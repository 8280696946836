import { getManifest, loadManifest, setManifest } from '@angular-architects/module-federation';
import { TenantBootstrapConfig } from './app/auth/data-access/tenant-bootstrap-config.type';
import { environment } from './environments/environment';
import { setupServiceWorker } from './service-worker/registration';

declare global {
  interface Window {
    bootstrapConfig: TenantBootstrapConfig;
  }
}

async function initializeBootstrap(): Promise<void> {
  await setupServiceWorker(getManifest());
  await import('./bootstrap');
}

async function fetchManifest(): Promise<void> {
  try {
    const response = await fetch(environment.tenantBootstrapConfigUrl);
    const data: TenantBootstrapConfig = (await response.json()) as TenantBootstrapConfig;
    window.bootstrapConfig = data;
    environment.env === 'local'
      ? await loadManifest('/assets/mf.manifest.local.json', true)
      : await setManifest(data.shellManifest, true);

    await initializeBootstrap();
  } catch (error) {
    console.error(error);
  }
}

await fetchManifest();
