export const environment = {
  manifestFileUrl: 'https://s3.amazonaws.com/s3-web-1.lucanet.dev/manifest/qa/mf.manifest.json',
  env: 'qa',
  tenantBootstrapConfigUrl:
    'https://qmtotgebi6.execute-api.eu-central-1.amazonaws.com/test/api/tenant/v1/configs/bootstrap?consumer=shell&tenantAlias=tenant-1',
  rum: {
    app_config: {
      id: 'af0a0e00-8ae1-404c-a4e0-c7a9407f033f',
      version: '1.0.0',
      region: 'eu-central-1'
    },
    app_monitor: {
      guestRoleArn: 'arn:aws:iam::884448998206:role/RUM-Monitor-eu-central-1-884448998206-5396407598071-Unauth',
      identityPoolId: 'eu-central-1:109d75e8-8a05-4089-8c6d-06fe309f45a3',
      endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com'
    }
  }
};
