import { CustomManifest } from '../app/utils/config';

const extractServiceWorkerManifest = (manifest: CustomManifest): Record<string, string> =>
  Object.values(manifest).reduce<Record<string, string>>((acc, { appId, rootUrl }) => {
    acc[appId] = rootUrl;

    return acc;
  }, {});

export const registerServiceWorker = async (): Promise<ServiceWorkerRegistration | null> => {
  try {
    return await navigator.serviceWorker.register('/worker.js', { scope: '/' });
  } catch (error: unknown) {
    console.error('Service worker registration failed', error);
  }

  return null;
};

export const waitForServiceWorker = async (
  registration: ServiceWorkerRegistration | null,
  maxAttempts = 3
): Promise<void> => {
  let count = maxAttempts;

  // Try 3 times to ensure the service worker is active before loading app
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (!registration || registration.active || count > maxAttempts) {
        clearInterval(interval);
        resolve();
      } else {
        count++;
      }
    }, 50);
  });
};

export const sendManifestUpdate = (
  registration: ServiceWorkerRegistration | null,
  serviceWorkerData: Record<string, string>
): void => registration?.active?.postMessage({ type: 'shellApplicationMapping', value: serviceWorkerData });

export const setupServiceWorker = async (manifest: CustomManifest): Promise<void> => {
  const registration = await registerServiceWorker();
  await waitForServiceWorker(registration);
  sendManifestUpdate(registration, extractServiceWorkerManifest(manifest));
};
